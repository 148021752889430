.hero-section {
  background-image: url("../../../../../public/media/images/HeroSection/hero-banner.svg");
  background-size: cover;
  background-position: center;
}
@media only screen and (max-width: 600px) {
  .hero-title {
    font-size: var(--fs-32) !important;
  }
}
