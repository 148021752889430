.risk-category {
	background-color: #f9fafb;
}
.risk-category-table {
	th,
	td {
		border: 1px solid #dadada;
		padding: 10px 12px;
	}
	th {
		font-family: 'mr_semi';
		color: var(--color3);
		background-color: #fff;
	}
	td {
		font-family: 'mr_reg';
	}
}
@media only screen and (max-width: 576px) {
	.portfolio-child-title {
		font-size: var(--fs-24);
	}
}
