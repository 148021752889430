.desktop-img {
  display: block;
}
.mobile-img {
  display: none;
}
@media only screen and (max-width: 600px) {
  .about-title {
    font-size: var(--fs-36) !important;
  }
  .desktop-img {
    display: none;
  }
  .mobile-img {
    display: block;
  }
}
