.nav-styling {
	opacity: 90%;
	bottom: 4rem;
	margin: 0 22rem;
}
.desktop-img {
	display: block;
}
.mobile-img {
	display: none;
}
.navbar-toggler {
	border: none;
	box-shadow: none !important;
}
.text_color {
	color: var(--defualt-white);
}
.active {
	border-bottom: 1px solid #f9d673 !important;
}
.nav-position {
	position: sticky;
	top: 0;
	z-index: 1000;
}
.navbar-toggler-icon {
	display: inline-block;
	width: 30px;
	height: 2px;
	background-color: #000;
	position: relative;
	transition: all 0.3s ease-in-out;
}

.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
	content: '';
	width: 30px;
	height: 2px;
	background-color: #000;
	position: absolute;
	left: 0;
	transition: all 0.3s ease-in-out;
}

.navbar-toggler-icon::before {
	top: -10px;
}

.navbar-toggler-icon::after {
	top: 10px;
}

.navbar-toggler.open .navbar-toggler-icon {
	transform: rotate(45deg);
	background-color: #000;
}

.navbar-toggler.open .navbar-toggler-icon::before {
	transform: rotate(90deg);
	top: 0;
}

.navbar-toggler.open .navbar-toggler-icon::after {
	transform: rotate(90deg);
	top: 0;
}
.active-impulse {
	color: var(--color2) !important;
}
@media (max-width: 1400px) {
	.nav-styling {
		margin: 0 10rem;
	}
}
@media (max-width: 1200px) {
	.nav-styling {
		margin: 0 10rem;
	}
}
@media (max-width: 1050px) {
	.nav-styling {
		margin: 0 5rem;
	}
}

@media (max-width: 991px) {
	/* .navbar-nav li a {
    color: var(--color1);
  } */
	.navbar-nav li a img,
	.navbar-nav li img {
		border-bottom: 0 !important;
	}
	.desktop-img {
		display: none;
	}

	.mobile-img {
		display: block;
	}
	.nav-styling {
		opacity: 100%;
		bottom: 100%;
		margin: 0;
		border-radius: 0 !important;
		background-color: var(--defualt-white) !important;
	}
	.text_color {
		color: var(--color1);
	}
	.nav-position {
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 1000;
	}
	.nav-bg-md {
		background-color: var(--defualt-white);
	}
}
@media only screen and (max-width: 600px) {
}
