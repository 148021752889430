@media only screen and (max-width: 576px) {
	.support-title {
		font-size: var(--fs-36);
	}
	.support-desc {
		font-size: var(--fs-16);
	}
	.support-sec-text {
		font-size: var(--fs-20);
	}
	.support-sec-desc {
		font-size: var(--fs-18);
	}
}
.form_control:focus {
	border: 1px solid #dee2e6 !important;
	box-shadow: none !important;
}
.sup-btn:hover {
	background-color: var(--color1) !important;
}
.sup-btn:active {
	background-color: var(--color1) !important;
}
.sup-btn:focus-visible {
	background-color: var(--color1) !important;
}
.sup-btn:disabled {
	background-color: var(--color1) !important;
}
