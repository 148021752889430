.product-hero-section {
	background-image: linear-gradient(#94ebf0, #ffffff);
	background-size: cover;
	background-position: center;
}
@media only screen and (max-width: 600px) {
	.hero-title {
		font-size: var(--fs-32) !important;
	}
}
