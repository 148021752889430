.contact-us-bg {
	background-color: var(--color1);
}
@media only screen and (max-width:576px) {
	.contactus-subtitle{
		font-size: var(--fs-36);
	}
	.contactus-desc{
		font-size: var(--fs-16);
	}
}