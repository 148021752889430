.btn-design {
	border: 0;
	background: transparent;
}
[type='search'] {
	outline: 0 !important;
}
::placeholder {
	color: var(--defualt-white);
	opacity: 1;
}

::-ms-input-placeholder {
	color: var(--defualt-white);
}
.lh-28 {
	line-height: 28.8px;
}
@media only screen and (max-width: 410px) {
	.input-focus-outline {
		width: 90%;
	}
}
