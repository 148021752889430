.disclosure-bg {
	background-color: #f3f3f3 !important;
}
.tbl-container {
	overflow-x: hidden;
	border-radius: 12px;
	border: 1px solid #dee2e6;
}
.table-content {
	min-width: 500px;
}
@media only screen and (max-width: 576px) {
	.disclosure-link-text {
		font-size: var(--fs-16);
	}
	.text-break {
		word-break: break-all;
	}
}
