.card_text {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease;
  transition-duration: 0.1s;

}

.card_text.show {
  max-height: 1000px;
  /* transition: max-height 0.6s ease-in-out; */
  transition-duration: 1s;
}

.card_text.hide {
  max-height: 0;
}
.team-card-shadow{
  box-shadow: none !important;
}