.your-portfolio {
	background-color: #f9fafb;
}
.stock-data-circle {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 1px solid #dadada;
}

.stock-data-container {
	display: flex;
	align-items: center;
	gap: 8px;
}
.stock-data-wrapper {
	display: flex;
	flex-wrap: wrap; 
	gap: 25px;
}

