.portfolio-nav-link.portfolio-active {
	color: var(--color1) !important;
	background: #f1fffe !important;
	border-left: 6px solid var(--color1) !important;
	border-radius: 0 !important;
	font-size: var(--fs-24);
	font-family: 'rw_reg';
	text-align: left;
	padding: 24px 16px 14px 16px !important;
}

.portfolio-nav-link {
	color: var(--color31) !important;
	background: transparent !important;
	border-left: 6px solid var(--color31) !important;
	border-radius: 0 !important;
	font-size: var(--fs-24);
	font-family: 'rw_reg';
	text-align: left;
	padding: 24px 16px 14px 16px !important;
}
