.dotted-line {
    border: none;
    border-top: 2px dashed var(--color1); /* Adjust thickness and color as needed */
    margin-top: 1rem;  /* Optional, match with your existing classes */
    margin-bottom: 1.25rem; /* Optional, match with your existing classes */
}
@media only screen and (max-width: 600px) {
    .support-text{
        font-size: var(--fs-36) !important;
        font-family:"rw_reg" !important;
    }
}