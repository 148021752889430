.card-section-style {
  padding: 0 6rem;
}

.bg-liner-v-color8 {
  background: linear-gradient(
    180deg,
    var(--color1) 400px,
    rgba(245, 250, 250, 0) 30%
  );
}
.slick-prev:before {
    content: '' !important; 
    background-image: url('../../../../../public/media/images/Team/team-arrow-left.svg');
    background-size: contain; 
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    display: block; 
}
.slick-next:before {
    content: '' !important; 
    background-image: url('../../../../../public/media/images/Team/team-arrow-right.svg');
    background-size: contain; 
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    display: block; 
}
@media only screen and (max-width: 1024px) {
  .card-section-style {
    padding: 0;
  }
  .bg-liner-v-color8 {
  background: linear-gradient(
    180deg,
    var(--color1) 410px,
    rgba(245, 250, 250, 0) 0%
  );
}
}
@media only screen and (max-width: 600px) {
  .team-subtitle {
    font-size: var(--fs-36) !important;
  }
  .card-section-style {
    padding: 0;
  }
  .bg-liner-v-color8 {
    background: linear-gradient(
      180deg,
      var(--color1) 520px,
      rgba(245, 250, 250, 0) 30%
    );
  }
}
